let about_me = {
    title: "About Me",
    body: "Hi my name is Hector Acosta, I am a software engineer curretly working in a startup. I graduate from University of Nebraska at Lincoln with a degree in Computer Science and a minor in Mathematics. I have created projects using Python, Javascript and Java. Although I also know C++ and php, I have not used them in a while. I spend my free time working out, watching TV or playing video games (RIP SCII next to Python 2 ). I hope you enjoy my website and if you want to get in contact with me, feel free to send me a Linkedin message.",
}

let work_experience = [
    {
        start: "May 2019",
        end: "Current",
        company: "SuiteSale",
        title: "Full Stack Developer",
        duties: [
            "Create RESTend points.",
            "Add new Features to the project.",
            "Maintain and deploy new code to different AWS services.",
            "Create new Request to third parties API's.",
            "Add CI to project."
        ]
    },
    {
        start: "Agust 2018",
        end: "May 2019",
        company: "Nebraska Departement of Transportation",
        title: "Database Developer",
        duties:[
            "Create new SSRS package for Nebraska Department of Transportation.",
            "Apply ETL to data gather from datawarehouse.",
        ]
    }
]

let important_projects = [
    {
        tilte: "Budget Tracker",
        body: "Web app capable of track user income and their expenses. The app use authenticaion and authorization.",
        footer: [
            "React","Python","Javascript", "Django", "HTML", "CSS"
        ],
        img: "ControllExpenses",
        url: "https://github.com/HectorCaAc/ControllExpenses"
    },
    {
        title:"Web Chat",
        body: "Web app that allows user send message in real time, as well as receiving it. The application is capable of doing it using sockets",
        footer: [
            "React", "NodeJS", "Express", "Javascript", "HTML", "CSS"
        ],
        img: "whatSsap",

    }
    ,{
        title: "Github Display",
        body: "SPA application, which allow their users to query a github user to gather information about their github page. Once an user is enter, the application will request the data from the free version of Github API.",
        footer:[
            "React","Javascript", "HTML", "CSS"
        ],
        img: "github",
        url: "http://github.hacasoftware.com/"
    }
]

let other_projects = [
    {
        title: "Post App",
        body: "Social media app that allow their users to post and see their friends posts, similar to the way Facebook does it.",
        footer: [
            "Django", "React", "HTML", "CSS"
        ],
        github:"https://github.com/HectorCaAc/PostApp"
    },
    {
        title: "Tasks Tracker",
        body: "Keeps track of tasks done by the user. The user can determine if an tasks was complete in the time given or not, and recived rewards for that",
        footer: [
            'Ract', 'Javascript', 'HTML', 'CSS'
        ],
        github:"https://github.com/HectorCaAc/TimerTask"
    },
    {
        title: "Portafolio V1",
        body: "First portafolio that I wrote after learning basics of HTML, Javascript and CSS",
        footer: [
            "Javascript", "HTML", "CSS", "JQuery", "Bootstrap" 
        ]
    }
]

let principalCard_data = {
    image: "portafolio",
    // body: "Current Resume",
    url: "https://s3-us-west-2.amazonaws.com/first.hacasoftware.com/Hector_Acosta.pdf"
}

export {about_me, important_projects, other_projects, work_experience, principalCard_data}