import React, { useState, useEffect } from 'react'

import '../styles/Experience.sass'
import '../styles/small_experience.sass'

export function Experience(props) {

    const [storeJobs, setstoreJobs] = useState([])
    const [currentWork, setCurrentWork] = useState({})
    const [fetchinData, setFetchinData] = useState(true)
    const [AllJobs, setAllJobs] = useState([])

    useEffect(() => {
        console.log('Jobs data was store inside of the react component not using props anymore');
        setstoreJobs(props.data)
        setCurrentWork(props.data[0])
        setFetchinData(false)
        setAllJobs(props.data.map((entry, key) => <li key={key}
            onClick={() => setCurrentWork(entry)} >{entry.company}</li>))
    }, [])

    if (fetchinData) {
        return (
            <div className="Experience">
                Loading Data
            </div>
        )
    }
    else {
        return (
            <div className="Experience">
                <div className="Experience_Options">
                    <ul>
                        {AllJobs}
                    </ul>
                </div>
                <div className="Experience_Selected">
                    <div className="Experience_Selected_Header">
                        <h2>Position :  <span>{currentWork.title}</span> @{currentWork.company}</h2>
                        <h4> Start Date  <span>{currentWork.start} </span>to  <span>{currentWork.end}</span></h4>
                    </div>
                    <div className="Experience_Selected_body">
                        <ul>
                            {currentWork.duties.map((description, key_2) =>
                                <li key={key_2}>{description}</li>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}