import React from 'react'

import "../styles/SmallCard.sass"
import "../styles/small_smallCard.sass"

import DescriptionIcon from '@material-ui/icons/Description'
import GitHubIcon from '@material-ui/icons/GitHub'
import WebAssetIcon from '@material-ui/icons/WebAsset';

export function SmallCard(props) {

    let footer = props.footer.map((entry, key) =>
        <span key={key} className="smallCard_footer_small">
            {entry}
        </span>
    )


    return (
        <div className="smallCard">
            <div className="smallCard_header">
                <div><DescriptionIcon fontSize="large" /></div>
                <div>
                    {props.github && <a href={props.github}><GitHubIcon /></a>}
                    { props.online &&  <WebAssetIcon/>}
                </div>
            </div>
            <div className="smallCard_body">
                <h3>{props.title}</h3>
                {props.description}
            </div>
            <div className="smallCard_footer">
                {footer}
            </div>
        </div>
    )
}
