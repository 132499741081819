import React, { useState, useEffect } from "react"

// Gatsby requirements not use right now
import { graphql, graphwl } from 'gatsby'
import Img from 'gatsby-image'

import image from "../static/images.js"

// Test data that can be import from data.js
import { about_me, important_projects, other_projects, work_experience, principalCard_data } from '../data'

import { Card } from '../components/Card'
import { SmallCard } from '../components/SmallCard'
import { Experience } from '../components/Experience'
import { PrincipalCard } from '../components/PrincipalCard'

import LinkedInIcon from '@material-ui/icons/LinkedIn'
import GitHubIcon from '@material-ui/icons/GitHub'


import "../styles/index.sass"
import "../styles/small_index.sass"

export default function Home() {

  let projects = important_projects.map((entry, key) =>
    <Card key={key} title={entry.title} body={entry.body} footer={entry.footer} image={image[entry.img]} url={entry.url} />)

  let small_projects = other_projects.map((entry, key) =>
    <SmallCard key={key} description={entry.body} title={entry.title} footer={entry.footer} github={entry.github} />
  )
  console.log(principalCard_data)
  return (
    <div>
      <NavBar />
      <div className="page">
        <div id="about_me" className="sections">
          <h2 className="title">About me</h2>
          <Card body={about_me.body} image={image.profiler} />
          <h2 className="title"> Experience</h2>
          <Experience data={work_experience} />
        </div>

        <div id="projects" className="sections">
          <h2 className="title">Projects</h2>
          {projects}
          <h2 className="title">Small Projects</h2>
          <div className="small_projects">
            {small_projects}
          </div>
        </div>
        <div className="sections" id="resume">
          <h2 className="title">Resume</h2>
          <PrincipalCard image={principalCard_data.image}
            body={principalCard_data.body} url={principalCard_data.url} />
        </div>
      </div>
      <SocialMediaLinks />
    </div>
  )
}

function NavBar() {
  return (
    <div className="Navbar_Container">
      <div className="Navbar">
        <div className="Navbar_item">
          <h2>Hector Acosta</h2>
        <div className="small_description">
          Learning new things everyday
          </div>
        </div>
        <div className="Navbar_options">
          <div className="Navbar_item"> <a href="#about_me"> About Me</a></div>
          <div className="Navbar_item"> <a href="#projects"> Projects</a></div>
          <div className="Navbar_item"> <a href="#resume"> Resume</a></div>
        </div>
      </div>
    </div>
  )
}

function SocialMediaLinks() {
  return (
    <div className="SocialMedia">
      <div className="SocialMedia_Links"><a href="https://github.com/HectorCaAc"><GitHubIcon /></a></div>
      <div className="SocialMedia_Links"><a href="https://www.linkedin.com/in/hectoracosta96"><LinkedInIcon /></a></div>
      <div className="SocialMedia_Links"> <a href="https://dev.to/hectorcaac">Dev</a></div>
    </div>
  )
}

export const query = graphql`
        query {
          file(relativePath: {eq: "Big.png"}){
            childImageSharp {
              fixed(width: 125, height: 125){
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
`