import profiler from './Hector.jpg'
import logo from './SMALL_H.png'
import whatsap from './Whatssap.png'
import controllExpenses from './controllExpenses.png'
import github from './github.png'
import portafolio from './smallPortafolio.png'

const images = {
    profiler: profiler,
    log: logo,
    whatSsap: whatsap,
    ControllExpenses: controllExpenses,
    github: github,
    portafolio: portafolio
}

export default images