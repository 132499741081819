import React from 'react'

import "../styles/Card.sass"
import "../styles/small_card.sass"

export function Card(props) {
    console.log(props.url);

    return (
        <div className="card">
            <div className="card_picture">
                <a href={props.url}><img src={props.image} alt=""/></a>
            </div>
            <div className="card_body">
                <div className="card_body_title">
                    {props.title}
                </div>
                <div className="card_body_body">
                    {props.body}
                </div>
                <div className="card_body_footer">
                    {props.footer}
                </div>
            </div>
        </div>

        
    )
}
