import React from 'react'

import { Button } from '@material-ui/core'

import images from '../static/images'

import '../styles/PrincipalCard.sass'

export function PrincipalCard(props) {
    return (
        <div className="PrincipalCard">
            <div className="principalCard_body">
                <div className="PrincipalCard_img">
                    <img src={images[props.image]} atl="image" />
                </div>
                <div className="PrincipalCard_lowerPart">
                    <div className="PrincipalCard_lowerPart_content">
                        {props.body}
                    </div>
                    <div className="PrincipalCard_lowerPart_button">
                        <a href={props.url}>
                            <Button variant="outlined" color="secondary">
                                Get CV
                        </Button>
                        </a>
                    </div>
                </div>

            </div>
        </div>
    )
}
